import React from "react";
import "./Article.css";
import Footer from "./Footer";

function Philosophy() {
    return (
        <>
        <div className="article">
            <h1>The Kamesan Philosophy</h1>
            <hr />
            <p>This website was built on the fundemental principle that the only way to improve at things is to do them. 
                Such a simple fact, and yet so easily overlooked, especially in today's golden age of nominally free apps, and self-proclaimed YouTube messiahs.
                Kamesan is the distilled essence of language learning, without ads, distractions, or clutter. And it's free.</p>
            
            <p>
                This approach is not new. Currently, there is an ever-growing online community of immersion-based language learners, and loads of awesome free software out there to make language learning today more efficient and fun than ever before.
                Unfortunately, it is not very user friendly, nor is it ergonomic to have to wrestle with a host of browser extensions, applications and software utilities during precious time that should be spent immersed in your target language.
                Kamesan is the first website to fully intergrate this software stack into a minimalist, ergonomic, and seamless tool that even the least technologically-inclined can use without frustration.
            </p>

            {/* <p>
                The existing tools will leave your screen looking like this:
            </p>

            <div className="placeholderDiv">
            <p>Pictures of ugly windows shit goes here...</p>
            </div>

            <p>
                With Kamesan, it can instead look like this:
            </p>

            <div className="placeholderDiv">
            <p>sexy pictures of this website go here...</p>
            </div> */}

            <h2>No Ads or Subscriptions!</h2>
            <hr />
            <p>
                This site was created purely for the benefit of myself, and other language learners in the same position as me, and knowing that this site might be useful to someone was all the motivation I needed to create it.
                I cannot take any credit for the idea of fully immersion-based language learning, nor can I claim that this website is anything but a companion tool for the amazing work of the <a href="https://github.com/ankitects/anki">Anki</a> contributors.
                It is because of this that I have decided not to make use of ads / subscriptions for the time being. Tracking ads are a violation of your human rights, and you should block them wherever possible.
            </p>

            <p>
                That being said, this site is not free to run, and I need your help to keep it going. Kamesan is and will be funded purely by user donations for as long as it is viable to do so. If you find this site useful,
                your donation can help keep it alive so that other people can find it useful too.
            </p>

            <h2>Duolingo can suck my balls</h2>
            <hr />
            <p>
                Duolingo claim that you can learn a language by playing quick, fun games for 10 minutes a day. That only comes to around 61 hours of "language learning" per year. To contrast, the average male spends more than 73 hours per year shitting.
                These pseudo-educational games have little or no real world effect on your ability to speak and understand your chosen language. Their approach is like learning to ride a bike by reading a book about it.
            </p>
            <p>
                Duolingo has established its place as the market leader in online language learning tools by creating a false sense of accomplishment for it's users, and by convincing them that they are doing something productive with their time,
                all while milking their users / victims for that sweet ad revenue. 
                They provide a convenient way for people to claim the intellectual merit of learning a language, without requiring any real effort. This is a fundementally unethical and predatory business model. Friends don't let friends use Duolingo. Or any other crappy language learning app for that matter.
            </p>
        </div>
        <Footer />
        </>
    )
}

export default Philosophy