import React from "react";
import "./Footer.css";

export default function Footer() {
    return (
        <div className="footer">
            <div className="siteTitle">Kamesan.net</div>
            <br />
            <div className="footerDetails">
                <div className="section">
                <b>E-Mail:</b> cameronholland24@gmail.com <br />
                <b>Reddit:</b> <a href="https://reddit.com/r/kamesan">r/kamesan</a> <br />
                <b>Discord:</b> Coming Soon (maybe)
                
                </div>

                <div className="section">
                <b>Thanks:</b> <br />
                <a href="https://apps.ankiweb.net/">Anki Contributors</a> <br />
                <a href="https://foosoft.net/">Foosoft Productions</a> <br />
                <a href="https://refold.la/simplified/">Refold Team</a> <br />
                <a href="http://www.alljapaneseallthetime.com/blog/archives/">AJATT Community</a><br />
                </div>

                <div className="section">
                <b>Resources:</b><br />
                <a href="/newhere">Getting Set Up</a><br />
                <a href="/philosophy">The Kamesan Philosophy</a><br />
                <a href="/faq">FAQs</a>
                </div>

                <div className="section">
                <b>Donations:</b><br />
                <a href="https://www.patreon.com/Kamesan">Patreon</a><br />
                </div>
                </div>
        </div>
    )
}