import './VideoSeekBar.css'
import useMeasure from 'react-use-measure'
import { useState, useEffect } from 'react'

export default function VideoSeekBar (props: {currentTime: number, setCurrentTime: React.Dispatch<React.SetStateAction<number>>, duration: number}) {
    const [ref, bounds] = useMeasure()
    // const [currentTime, setCurrentTime] = useState(0)

    useEffect(() => {
        var progress = document.getElementById("videoProgress")
        progress!.style.width = Math.round(props.currentTime / props.duration * bounds.width) + "px"
    }, [props.currentTime, bounds, props.duration])

    function updateTooltip(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        var tooltip = document.getElementById("videoTooltip")
        if (tooltip) {
            tooltip.style.left = `calc(${e.pageX}px - 15px - 75px)`

            var elapsed = secondsToTimestamp(Math.round(e.nativeEvent.offsetX / bounds.width * props.duration))
            var fDuration = secondsToTimestamp(props.duration)

            tooltip.innerText = `${elapsed}`
            
        }

    }

    function updateTime(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        var percentage = e.nativeEvent.offsetX / bounds.width
        var video = document.getElementById("video") as HTMLVideoElement | HTMLElement | null

        if ( video instanceof HTMLVideoElement ) {
            video.currentTime = Math.round(e.nativeEvent.offsetX / bounds.width * props.duration)
            props.setCurrentTime(percentage * props.duration)
        }
        
        // var progress = document.getElementById("videoProgress")
        // progress!.style.width = Math.round(percentage * 100) + "%"
    }

    function secondsToTimestamp(d: number) {
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        var sDisplay = s < 10 ? "0" + s : String(s)
        var mDisplay = m < 10 ? "0" + m : String(m)
        return h > 0 ? `${h}:${mDisplay}:${sDisplay}` : `${mDisplay}:${sDisplay} `
    }

    return (
        <div ref={ref} className='videoSlider' onMouseMove={(e) => {updateTooltip(e)}} onMouseDown={(e) => {updateTime(e)}}>
          <div id='videoProgress'></div>
          <span id='videoTooltip'>N/A</span>
        </div>
    )
}