import React from "react";
import "./Article.css";
import Footer from "./Footer";

function NewHere() {
	return (
	<>
	<div className="article">
		<h1>Getting Set Up</h1>
		<hr />
		<p>
			This website is designed to be ingrated with Anki, which is a really cool flashcard tool. There's already enough people on the internet who will be happy to start foaming at the mouth explaining how great Anki is, so I won't bother going over it here. It really is great though, trust me. To use this website to it's full potential, you'll first have to head over to the <a href="https://apps.ankiweb.net/">Anki install page</a> and get set up.
		</p>
		<p>
			Done that? Great! Now you'll need to install the <a href="https://foosoft.net/projects/anki-connect/">AnkiConnect</a> addon from FooSoft Productions. Instructions are on the linked page under the header "Installation". This is the addon that allows this website to create Anki cards with the push of a button.<sup>*</sup>
		</p>
		<p>
			And that's it! Now you're ready to start using Kamesan to take your Japanese study routine to a whole new level! <a href="https://www.youtube.com/watch?v=79DijItQXMM">You're Welcome!</a>
		</p>
		<p className="footnote">
			<sup>*</sup>For those that are interested, this addon runs a HTTP server on your machine and exposes Anki features through that. Kamesan can then send HTTP requests to this server to create cards and such.
			Yes, it is indeed weird that this works and yes, you are right to be a little worried about it.
		</p>
	</div>
	<Footer />
	</>
)
}

export default NewHere
