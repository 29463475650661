import React from "react";
import { ActionFunctionArgs, LoaderFunction, ParamParseKey, Params, useLoaderData } from "react-router-dom";
import "./BrowseSeries.css";
import Footer from "./Footer";
// TODO:
// Add a featured series of the day to display
// Add a donations banner before the "all" section

  interface SeriesDetails {
    id: number, 
    en_title: string,
    jp_title: string,
    description: string,
    poster_link: string,
    isanime: boolean
  }

  interface loaderResponse {
    popularAnime: [SeriesDetails],
    popularLiveAction: [SeriesDetails],
    all: [SeriesDetails]
  }


export const loader: LoaderFunction = async () => {
    var episodeRes = await fetch(`https://kamesan.net/api/series`)
    if (!episodeRes.ok) {
      throw episodeRes
    }
    var popularAnimeRes = await fetch(`https://kamesan.net/api/mostPopularAnime`)

    if (!popularAnimeRes.ok) {
      throw popularAnimeRes
    }

    var popularLiveActionRes = await fetch(`https://kamesan.net/api/mostPopularLiveAction`)

    if (!popularLiveActionRes.ok) {
      throw popularLiveActionRes
    }

    var allSeriesEpisodes = await episodeRes.json() as [SeriesDetails]
    var popularAnimeSeries = await popularAnimeRes.json() as [SeriesDetails]
    var popularLiveActionSeries = await popularLiveActionRes.json() as [SeriesDetails]


    var all = allSeriesEpisodes as [SeriesDetails]
    var popularAnime = popularAnimeSeries as [SeriesDetails]
    var popularLiveAction = popularLiveActionSeries as [SeriesDetails]

    var out: loaderResponse = {'popularAnime':popularAnime, 'popularLiveAction':popularLiveAction, 'all':all}
    return out
  
  }    


function BrowseSeries() {
    const seriesData = useLoaderData() as loaderResponse

    let x = [0]
    for (let index = 0; index < 100; index++) {
        x.push(1);
        
    }

    return (
        <div className="imageBackground">
        
        <div className="mainContainer">
        <div className="annoyingBar">
            <a href="https://www.surveymonkey.co.uk/r/SP9S96D" className="surveyButton">Okay!</a>
            <div id="annoyingText">
            <span>Take our survey to let us know how we can make this site work better for you. It'll only take like 10 seconds, I promise!</span>
            </div>
        </div>

            <div className="seriesSection">
                <div className="sectionHeader">
                    <h1>Popular Anime</h1>
                    <p>It's okay. I'm not judging you.</p>
                    <hr />
                </div>
                <div className="seriesContainerFlex">
                    {seriesData['popularAnime'].map(y => {if (y.isanime) {return (
                    <div>
                        <a href={`/series/${y.id}`}>
                        <img loading="lazy" src={y.poster_link}></img>
                        <span className="seriesTitle">{y.en_title}</span>
                        </a>
                    </div>
                    )}
                    }
                    )}
                    
                </div>
            </div>

            <div className="seriesSection">
                <div className="sectionHeader">
                    <h1>Popular Live-Action</h1>
                    <p>Believe it or not, Japanese people are actually 3-dimensional!</p>
                    <hr />
                </div>
                <div className="seriesContainerFlex">
                    {seriesData['popularLiveAction'].map(y => {if (!y.isanime) {return (
                    <div>
                        <a href={`/series/${y.id}`}>
                        <img loading="lazy" src={y.poster_link}></img>
                        <span className="seriesTitle">{y.en_title}</span>
                        </a>
                    </div>
                    )}
                    }
                    )}
                    
                </div>
            </div>

            <div className="seriesSection">
                <div className="sectionHeader">
                    <h1>Everything</h1>
                    <p>Here's every series we have right now. Suggestions are always welcome.</p>
                    <hr />
                </div>
                <div className="everySeriesContainer">
                    {seriesData['all'].map(y => 
                    <div>
                        <a href={`/series/${y.id}`}>
                        <img loading="lazy" src={y.poster_link}></img>
                        <span className="seriesTitle">{y.en_title}</span>
                        </a>
                    </div>
                    )}
                    
                </div>
            </div>


        </div>
        <Footer />
        </div>
    )
}

export default BrowseSeries