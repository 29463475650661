import React from 'react';
import './App.css';
import VideoPlayer from './VideoPlayer';
import SidebarHomeView from './SidebarHomeView';
import Dictionary from './Dictionary';
import CountdownTimer from './CountdownTimer';
import SessionOverview from './SessionOverview';
import Navigation from './Navigation';
import { useState } from 'react';
import { definedWord } from './VideoPlayer';
import { ActionFunctionArgs, LoaderFunction, ParamParseKey, Params, useLoaderData } from 'react-router-dom';
import Footer from './Footer';
import { BooleanSetting, MultipleChoiceSetting, RestrictedChoiceSetting } from './SettingsMenuNew';

const PathNames = {
  blah: '/watch/:seriesId/:episodeNumber'
} as const;

interface Args extends ActionFunctionArgs {
  params: Params<ParamParseKey<typeof PathNames.blah>>
}

interface EpisodeDetails {
  id: number, 
  series_id: number, 
  episode_number: number, 
  video_link: string, 
  subtitles_link: string, 
  view_count: number,
  en_title: string,
  jp_title: string,
  description: string,
  poster_link: string,
  is_anime: boolean
}

export interface LoaderFunctionResponse {
  currentEpisode: EpisodeDetails,
  allSeriesEpisodes: [EpisodeDetails]
}


export const loader: LoaderFunction = async ({ params }:Args) => {
  var episodeRes = await fetch(`https://kamesan.net/api/series/${params.seriesId}/episode/${params.episodeNumber}`)
  if (!episodeRes.ok) {
    throw episodeRes
  }
  var allSeriesEpisodes = await episodeRes.json() as [EpisodeDetails]
  // the following line fucks things up if there are episodes missing. Fix it.
  var currentEpisode = allSeriesEpisodes.filter(episode => {return episode.episode_number === Number(params.episodeNumber)})
  var out = {"currentEpisode": currentEpisode[0], "allSeriesEpisodes": allSeriesEpisodes} as LoaderFunctionResponse
  return out

}


function App() {

  // Options passed to settings menu
  const [pauseOnHover, setPauseOnHover] = useState(true)
  const [subtitlesBorder, setSubtitlesBorder] = useState(true)

  // default values for front of card
  const [ankiCardFront, setAnkiCardFront] = useState<{ value: string; label: string; }[]>([{value: "sentence", label: "Sentence"}])

  // default values for back of card
  const [ankiCardBack, setAnkiCardBack] = useState<{ value: string; label: string; }[]>([{value: "reading", label: "Word w/ Furigana"}, {value: "video", label: "Video Excerpt"},  {value: "definition", label: "Definition"}])

  // default values for size of subtitles
  const [subtitlesSize, setSubtitlesSize] = useState<{ value: string; label: string; }>({value: "medium", label: "Medium"})

  const settings : (BooleanSetting | RestrictedChoiceSetting | MultipleChoiceSetting)[] = [
    {
      type: "BooleanSetting", 
      legend: "Pause video on subtitle hover?", 
      value: pauseOnHover, 
      setterFunction: () => {setPauseOnHover(!pauseOnHover)}
    },

    {
      type: "BooleanSetting", 
      legend: "Make subtitles chunky?", 
      value: subtitlesBorder, 
      setterFunction: () => {setSubtitlesBorder(!subtitlesBorder)}
    },

    {
      type: "MultipleChoiceSetting;", 
      legend: "Anki card front values:", 
      value: ankiCardFront, 
      options: [
        {value: "video", label: "Video Excerpt"},
        {value: "word", label:"Word"},
        {value: "reading", label:"Word w/ Furigana"},
        {value: "sentence", label:"Sentence"},
        {value: "definition", label:"Definition"}
      ], 
      setterFunction: (newValue: { value: string; label: string; }[]) => {setAnkiCardFront(newValue)}
    },

    {
      type: "MultipleChoiceSetting;", 
      legend: "Anki card back values:", 
      value: ankiCardBack, 
      options: [
        {value: "video", label: "Video"},
        {value: "word", label:"Word"},
        {value: "reading", label:"Word w/ Furigana"},
        {value: "sentence", label:"Sentence"},
        {value: "definition", label:"Definition"}
      ], 
      setterFunction: (newValue: { value: string; label: string; }[]) => {setAnkiCardBack(newValue)}},

      {
        type: "RestrictedChoiceSetting", 
        legend: "Subtitles Size:", 
        value: subtitlesSize, 
        options: [
          {value: "s", label: "Small"},
          {value: "m", label:"Medium"},
          {value: "l", label:"Large"},
          {value: "xl", label:"Obese"},
        ], 
        setterFunction: (newValue: { value: string; label: string; }) => {setSubtitlesSize(newValue)}
      }
  ]


  const[selectedWord, setSelectedWord] = useState<definedWord>()
  const[wordContext, setWordContext] = useState<[definedWord]>()
  const[addedWords, setAddedWords] = useState(0)
  const[searchedWords, setSearchedWords] = useState(0)
  const[dialogueStart, setDialogueStart] = useState(0)
  const[dialogueEnd, setDialogueEnd] = useState(0)

  const videoProperties = useLoaderData() as LoaderFunctionResponse
  // console.log(videoProperties)

  function handleSelectedWordChange(word: definedWord) {
    setSearchedWords(prevState => {return prevState + 1})
    setSelectedWord(word)
  }

  function handleWordContextChange(context: [definedWord]) {
    setWordContext(context)
  }

  function handleDialogueStartChange(start: number) {
    setDialogueStart(start)
  }

  function handleDialogueEndChange(end: number) {
    setDialogueEnd(end)
  }

  if (!videoProperties) {
    throw Error
  }
  return (
    <>
    <div className="App">
      <VideoPlayer video={videoProperties.currentEpisode.video_link} subs={`https://kamesan.net/api/subtitles/${videoProperties.currentEpisode.id}`} settings={settings} updateWord={handleSelectedWordChange} updateContext={handleWordContextChange} updateDialogueStart={handleDialogueStartChange} updateDialogueEnd={handleDialogueEndChange}/>

      <div className='widgetsRight'>
      {/* {selectedWord && wordContext &&
      <Dictionary settings={settings} word={selectedWord} context={wordContext} dialogueStart={dialogueStart} dialogueEnd={dialogueEnd} videoLink={videoProperties.currentEpisode.video_link} updateAddedAnkiWords={setAddedWords}/>
      }

      <CountdownTimer />
      <SessionOverview addedWordCount={addedWords} dictionaryLookupsCount={searchedWords}/>
      <Navigation seriesDetails={videoProperties}/> */}
      {/* {selectedWord && wordContext && */}
      <SidebarHomeView seriesDetails={videoProperties} addedWordCount={addedWords} dictionaryLookupsCount={searchedWords} settings={settings} word={selectedWord} context={wordContext} dialogueStart={dialogueStart} dialogueEnd={dialogueEnd} videoLink={videoProperties.currentEpisode.video_link} updateAddedAnkiWords={setAddedWords}/>
{/* } */}
      </div>
      
      <div className='titleContainer'>
        <div className='title'>

          <h1>{videoProperties.currentEpisode.en_title}, Episode {videoProperties.currentEpisode.episode_number}</h1>
          {/* <div className='reactions'>
          <button><img src='https://cdn3.emoji.gg/emojis/4540-pepe-praying.gif' alt="praying pepe"></img></button>
          <button><img src="https://cdn3.emoji.gg/emojis/2470-cat-disagreeing.gif" alt="disagreeing cat"/></button>
          <button><img src="https://cdn3.emoji.gg/emojis/2086-chadgif.gif" alt="chad"/></button>
          
          </div> */}
          <br />
          <p>{videoProperties.currentEpisode.view_count} Views</p>

        </div>
      </div>
      
      
    </div>
    <Footer />
    </>
  );
}

export default App;
