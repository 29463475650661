import React from "react"
import "./Article.css";
import Footer from "./Footer";

export default function Faq() {
    return (
        <>
            <div className="article">
                <h1>Frequently Asked Questions</h1>
                <hr />
                <h2>Why can't I download the video clips from my Anki cards locally?</h2>
                <p>I made the decision to release this site in it's most basic form in order to assess its' popularity and viability, and I plan to greatly expand the site's functionality as time goes on.
                    Implementing this feature will be one of my top priorities once I establish whether money from donations will be able to cover the increased running costs that come with proccessing the videos.
                </p>

                <h2>Who made this site and why?</h2>
                <p>
                    Just me! I made this site to combat the mental atrophy brought on by spending a gap year studying music at Ayrshire College.
                    <br />
                    Jokes aside, as a Japanese learner, I just thought it would be a cool idea to mash together a load of the tools I was already using into one streamlined workflow.
                    I thought that such a tool might be useful to other people in the same situation as me, so I decided to bring it into reality.
                    <br />
                    This site is my way of giving back to the Japanese language learning community which has helped me to get this far in my language learning journey.
                </p>
                <h2>Why's it called Kamesan?</h2>
                <p>
                    I have no idea. I don't even like turtles / tortoises that much.
                    It does kind of match the color scheme I guess.
                </p>
                <h2>Isn't this illegal?</h2>
                <p>
                    Maybe?<br />
                    The fact that this site is free and educational tips the balance to fair use according to UK law.
                    I do not offer English language subtitles either, so that means people are unlikely to use it for anything other than it's intended purpose.
                    I suppose copyright holders could take legal action of they really wanted to, but they've got much bigger fish to fry.
                </p>

                <h2>When are you going to add x series?</h2>
                <p>
                    If you want me to add stuff, get in touch. I'm planning to make a discord server depending on how popular this site gets, so in future I'll take requests there.
                    Until then, e-mail is fine. My address should be in the footer of this page.
                </p>

                <h2>I'd like to buy this site / the technology behind it.</h2>
                <p>
                    Contact me with any business offers. I'm open to negotiate, subject to certain terms and conditions.
                </p>



            </div>
            <Footer />

        </>
    )
}