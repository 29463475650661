import React from "react";
import { ActionFunctionArgs, LoaderFunction, ParamParseKey, Params, useLoaderData } from "react-router-dom";
import "./ViewSeries.css";
import Footer from "./Footer";

const PathNames = {
    blah: '/series/:seriesId'
  } as const;
  
  interface Args extends ActionFunctionArgs {
    params: Params<ParamParseKey<typeof PathNames.blah>>
  }
  
  interface EpisodeDetails {
    id: number, 
    series_id: number, 
    episode_number: number, 
    video_link: string, 
    subtitles_link: string, 
    view_count: number,
    en_title: string,
    jp_title: string,
    description: string,
    poster_link: string,
    is_anime: boolean
  }

export const loader: LoaderFunction = async ({ params }:Args) => {
    var episodeRes = await fetch(`/api/series/${params.seriesId}/`)
    if (!episodeRes.ok) {
      throw episodeRes
    }
    var allSeriesEpisodes = await episodeRes.json() as [EpisodeDetails]
    // the following line fucks things up if there are episodes missing. Fix it.
    // var currentEpisode = allSeriesEpisodes.filter(episode => {return episode.episode_number === Number(params.episodeNumber)})
    var out = allSeriesEpisodes as [EpisodeDetails]
    return out
  
  }

function ViewSeries() {
    const seriesData = useLoaderData() as [EpisodeDetails]

    // console.log(seriesData)
    return (
        <div className="imageBackground">
            <div className="mainContainer">

                <div className="seriesHeader">
                    <div className="seriesPoster">
                        <img src={seriesData[0].poster_link} alt={seriesData[0].en_title} />
                    </div>
                    <div className="seriesInfo">
                    <span className="seriesPageTitle">{seriesData[0].en_title}</span>
                    <span className="seriesDescription">{seriesData[0].description}</span>
                    </div>
                </div>

                <div className="EpisodesListHeader"><h1>All Episodes</h1></div>
                <hr />

                <div className="seriesEpisodes">
                    {seriesData.map(x => {return(
                        <div>
                            <a href={`/watch/${x.series_id}/${x.episode_number}`}>
                                <img className="episodeThumbnail" src={x.poster_link}></img>
                                <span className="episodeLabel">Episode {x.episode_number}</span>
                            </a>
                        </div>
                    )})}
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default ViewSeries;