// import React, { ChangeEventHandler } from 'react';
import './VideoPlayer.css';
// import SettingsMenu from './SettingsMenu';
import { useState, useEffect } from 'react';
import { BooleanSetting, MultipleChoiceSetting, RestrictedChoiceSetting } from './SettingsMenuNew';
import VideoSeekBar from './VideoSeekBar';

interface Subtitle {
  id: string, // The library gives the id as a string. Weird, I know.
  startTime: string,
  startSeconds: number,
  endTime: string,
  endSeconds: number,
  text: string
}

interface SubtitleArray extends Array<Subtitle> {}

export interface definedWord {
  word: string,
  contextualForm: String,
  startPos: Number
  definitions: [
    [string]
  ]
  reading: [{[Key: string]: string}]
}

function VideoPlayer(props: { 
      video: string, 
      subs: string, 
      settings: (BooleanSetting | RestrictedChoiceSetting | MultipleChoiceSetting)[],
      updateWord: (word: definedWord) => void, 
      updateContext: (context: [definedWord]) => void
      updateDialogueStart: (start: number) => void,
      updateDialogueEnd: (end: number) => void,
    }) {
  
  // Do all of the following need to be stateful??

  const [isPlaying, setIsPlaying] = useState(false) // True: video is playing, False: video is not poaying
  const [currentTime, setCurrentTime] = useState(0) // video player current time as seconds
  const [subtitlesArray, setSubtitlesArray] = useState<SubtitleArray>() // Subtitles for current video as a SubtitleArray
  const [currentSubtitleID, setCurrentSubtitleId] = useState(0) // The index of the current subtitle in subtitlesArray. Makes updating the subtitles more efficient.
  const [subtitleElementHtml, setSubtitleElementHtml] = useState<JSX.Element>()
  const [subtitleText, setSubtitleText] = useState<String>()
  const [videoDuration, setVideoDuration] = useState<number>(100)

  const [showSettings, setShowSettings] = useState(false)

  useEffect(() => {

    // console.log("useeffect called")

    var subsElement = document.getElementById("subtitle")

    if (subtitlesArray && subsElement) {
      try{
      var current = subtitlesArray[currentSubtitleID]
      // console.log(current)
      // console.log(currentTime)
      var next = subtitlesArray[currentSubtitleID + 1]

      // for debugging
      // document.title=String(currentSubtitleID) + " " + String(currentTime) + " " + String(current.startSeconds) + " " + String(current.endSeconds)

      if (next.startSeconds <= currentTime && currentTime <= next.endSeconds) {
        setSubtitleText(next.text)
        setSubtitleElementHtml(undefined)
        setCurrentSubtitleId(currentSubtitleID + 1)
      }

      // Is playback on the same subtitle as it was last time?
      else if (current.startSeconds <= currentTime && currentTime < current.endSeconds) {

        // the following will not work once youve implemented spans. fix it.
        if (subtitleText !== current.text) {
          setSubtitleText(current.text)
          setSubtitleElementHtml(undefined)
        }
      }

      // is playback on the gap between the current subtitle and the next?
      else if (current.endSeconds <= currentTime && currentTime < next.startSeconds) {
        setSubtitleText('')
        setSubtitleElementHtml(undefined)
      }

      // Is playback on the next subtitle in the file? if yes, update state.
      

      else if (currentTime < subtitlesArray[0].startSeconds) {
        console.log("at the start of the video!")
        setSubtitleText('')
        setSubtitleElementHtml(undefined)
        setCurrentSubtitleId(0)
      }

      // If none of the above, user must have skipped somewhere else in the video.
      else {
        // console.log("skipped somewhere")

        // The reasoning why this line uses index + 2 is complicated, but it's there. Trust me.
        var newCurrent = subtitlesArray.find((value, index, array) => {return (array[index + 2].endSeconds >= currentTime)})

        if (newCurrent) {
          setCurrentSubtitleId(+newCurrent.id)
        }
        else {
          throw new Error("No subtitle was found at this point in playback.")
        }
      }
    
    }

    catch{
      setCurrentSubtitleId(subtitlesArray.length - 2)
      setSubtitleText('')
      setSubtitleElementHtml(undefined)
    }
  
  }
  else if (!subtitlesArray) {
    // console.log("bosh")
    fetchUserData(props.subs)
  }
  
  

  }, [subtitlesArray, subtitleText, currentSubtitleID, currentTime, props.subs])

  async function fetchUserData(url: string) {
    // gets JSON-ified subtitles data from url provided by props.subs
    const response = await fetch(url);
    setSubtitlesArray(await response.json());
  }

  function videoUpdateSeekBar() {
    // Updates the progress of the seek bar based on the progress of the video

    var video = document.getElementById("video") as HTMLVideoElement | HTMLElement | null
    // var seekBar = document.getElementById("seekBar") as HTMLInputElement | HTMLElement | null

    if ( video instanceof HTMLVideoElement) {
      // seekBar.valueAsNumber = (500 / video.duration) * video.currentTime
      setCurrentTime(video.currentTime)
    }
    else {
      throw new Error("No element with id 'video' and type 'HTMLVideoElement'")
    }
  }

  function seek(seconds: number) {
    // moves video playback to however many seconds from the start

    var video = document.getElementById("video") as HTMLVideoElement | HTMLElement | null

    if ( video instanceof HTMLVideoElement) {
      video.currentTime = seconds
      setCurrentTime(video.currentTime)
    }
    else {
      throw new Error("No element with id 'video' and type 'HTMLVideoElement'")
    }
  }

  function previousDialogue() {
    if (subtitlesArray) {
      if (subtitleText !== '') {
        seek(subtitlesArray[currentSubtitleID - 1].startSeconds)
        setCurrentSubtitleId(currentSubtitleID - 1)
      }
      else {
        seek(subtitlesArray[currentSubtitleID].startSeconds)
        setCurrentSubtitleId(currentSubtitleID)
      }
    }
    else {
      throw new Error("SubtitlesArray is undefined")
    }
  }

  function beginningDialogue() {
    if (subtitlesArray) {
      seek(subtitlesArray[currentSubtitleID].startSeconds)
    }
    else {
      throw new Error("SubtitlesArray is undefined")
    }
  }

  function nextDialogue() {
    if (subtitlesArray) {
      seek(subtitlesArray[currentSubtitleID + 1].startSeconds)
      // setCurrentSubtitleId(currentSubtitleID + 1)
    }
    else {
      throw new Error("SubtitlesArray is undefined")
    }
  }

  function videoPausePlay() {
    // If the video is playing, pause and update state, and vice versa

    var video = document.getElementById("video") as HTMLVideoElement | HTMLElement | null

    if ( video instanceof HTMLVideoElement) {
      if (video.paused) {
        video.play()
        setIsPlaying(true)
      }
      else {
        video.pause()
        setIsPlaying(false)
      }
    }
    else {
      throw new Error("No element with id 'video' and type 'HTMLVideoElement'")
    }
  }

  function Subtitle() {
    let style: React.CSSProperties
    if (!subtitleElementHtml) {
      if (subtitleText) {
        if (props.settings.find(o => o.legend === "Make subtitles chunky?")?.value) {
          style = {
            // textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
            // WebkitTextStroke: "1.5px black",
            background: "#00000088",
            fontWeight: "500",
            cursor: "wait"
          
          }
        }
        else {
          style = {}
        }

      let sizeSetting = props.settings.find(o => o.legend === "Subtitles Size:")
      if (sizeSetting?.type === "RestrictedChoiceSetting") {
        switch (sizeSetting.value.value) {
          case "s":
            style.fontSize = "1vw";
            break;
          case "m":
            style.fontSize = "1.3vw";
            break;
          case "l":
            style.fontSize = "1.6vw";
            break;
          case "xl":
            style.fontSize = "2vw";
            break;
        }
      }

        return <span id="subtitle" style={style} onMouseOver={defineHoveredLine}>{ subtitleText }</span>
      }
    }
    return <span id="subtitle" onMouseOver={definedSubtitleMouseOver}>{ subtitleElementHtml }</span>
  }

  function definedSubtitleMouseOver() {
    if (isPlaying && props.settings.find(o => o.legend === "Pause video on subtitle hover?")?.value) {
      videoPausePlay()
    }
  }

  function responseToHTML(obj: [definedWord]) {
    let style: React.CSSProperties
    if (props.settings.find(o => o.legend === "Make subtitles chunky?")?.value) {
      style = {
        // textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
        // WebkitTextStroke: "1.5px black",
        background: "#00000088",
        fontWeight: "500",
        borderRadius: "10px",
        padding: "2px"
      
      }
    }
    else {
      style = {}
    }

    let sizeSetting = props.settings.find(o => o.legend === "Subtitles Size:")
      if (sizeSetting?.type === "RestrictedChoiceSetting") {
        switch (sizeSetting.value.value) {
          case "s":
            style.fontSize = "1vw";
            break;
          case "m":
            style.fontSize = "1.3vw";
            break;
          case "l":
            style.fontSize = "1.6vw";
            break;
          case "xl":
            style.fontSize = "2vw";
            break;
        }
      }


    return <span style={style}>{obj.map(function(item) {

        if (item.definitions) {
          return <span onClick={() => updateExportedVariables(item, obj)} style={{ cursor: "pointer" }} className="definedWord" key={obj.indexOf(item)}>{ item["contextualForm"] }</span>
        }
        else if (item.contextualForm !== '\n') {
          style.cursor = "pointer"
          return <span key={obj.indexOf(item)} style={{ cursor: "default" }}>{ item["contextualForm"] }</span>
        }
        else{
          return <></>
        }
      })
    }
    </span>
  }

  function updateExportedVariables(item: definedWord, context: [definedWord]) {
    if (subtitlesArray) {
      props.updateWord(item)
      props.updateContext(context)
      props.updateDialogueStart(subtitlesArray[currentSubtitleID].startSeconds)
      props.updateDialogueEnd(subtitlesArray[currentSubtitleID].endSeconds)
    }
    else {
      throw new Error("subtitles array is undefined!")
    }
  }

  async function defineHoveredLine() {
    if (isPlaying && props.settings.find(o => o.legend === "Pause video on subtitle hover?")?.value) {
      videoPausePlay()
    }

    const text = subtitleText
    if (text) {

      const url = "https://kamesan.net/api/getSelectedWord"

      const bodyObj = {
        text: text
      }

      const request = new Request (url, {
        method: 'POST',
        body: JSON.stringify(bodyObj),
        headers: new Headers({
          'Content-Type': 'application/json'
        })
      })

        await fetch(request)
        .then(res => res.json())
        .then(res => {
          var response = res as [definedWord]
          setSubtitleElementHtml(responseToHTML(response))
        })
        
    }
    
    else {
      throw new Error("No current subtitle to define!")
    }
  }

  function toggleSettings() {
    setShowSettings(!showSettings)
  }

  function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    var key = e.key
    e.preventDefault()
    // console.log(key)
    if (key === ' ') {
      videoPausePlay()
    }
    else if(key === 'ArrowRight') {
      nextDialogue()
    }
    else if(key === 'ArrowLeft') {
      previousDialogue()
    }
  }

  function setDuration() {
    var video = document.getElementById("video") as HTMLVideoElement | HTMLElement | null
    if ( video instanceof HTMLVideoElement) {
      setVideoDuration(video.duration)
    }
  }

  return (
    <div className="VideoPlayer" tabIndex={0} onKeyDown={handleKeyPress}>
      <div className="videoContainer">
        <div>

          <video id="video" disablePictureInPicture onCanPlay={setDuration} controlsList="nodownload" onTimeUpdate={videoUpdateSeekBar} onClick={videoPausePlay} src={props.video}></video>

          <div className='subtitleContainer'>
            <Subtitle></Subtitle>
          </div>

        </div>

        {/* { showSettings &&
            <SettingsMenu settings={props.settings} show={showSettings} toggle={toggleSettings}/>
          } */}

      </div>
      <div className="ControlsContainer">
        <VideoSeekBar currentTime={currentTime} setCurrentTime={setCurrentTime} duration={videoDuration}/>
        <div className='Buttons'>
        <button className='LeftButton' onClick={videoPausePlay}>{isPlaying ? "⏸" : "▶"}</button>
        <button title="Previous Dialogue (left arrow key)" className='MiddleButton' onClick={previousDialogue}>⏪</button>
        <button title="Replay Dialogue" className='MiddleButton'onClick={beginningDialogue}>🔁</button>
        <button title="Next Dialogue (right arrow key)" className='MiddleButton'onClick={nextDialogue}>⏩</button>
        {/* <button className='RightButton' onClick={toggleSettings}>settings</button> */}
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;