import React from "react";
import Typewriter from 'typewriter-effect';
import './Homepage.css';
import Footer from "./Footer";

// TODO: 

function Homepage() {
    return (
        <>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" /> 
        <div className="titleBanner">
                    <div className="pageTitle">
                        Immersion, <br />Made <Typewriter 
                            options={{
                                strings: ['Simple', 'Elegant', 'Efficient', 'Fun', 'Easy', 'Effective'],
                                autoStart: true,
                                loop: true,
                            }} />
                    </div>                    

                    <div className="buttonContainer">
                        <a href="/browse" >
                            <button>Check out all of our series</button>
                        </a>
                    </div>

                    {/* <div id="downArrow">↓</div> */}
        </div>
        <div className="bottom">
                    <div className="mainContent">
                        <a className="row content" href="newhere">
                            <h1>New Here?</h1><br />
                            <p>Learn how to use this site to it's full potential by intergrating it with Anki! It's really simple to do, trust me.</p>
                        </a>
                        <a className="row content" href="https://www.patreon.com/Kamesan">
                            <div className="thing">
                            <h1>Support us!</h1><br />
                            <p>I plan to keep this site free for everyone, without ads, for as long as it is possible to do so. Only you can help keep that dream alive. Thanks for your support!</p>
                            </div>
                        </a>
                        <a className="row content" href="philosophy">
                            <h1>The Kamesan Philosophy</h1><br />
                            <p>
                                Textbooks, mobile apps, and self-proclaimed messiahs are great for beginners, but they quickly become ineffective.
                                Kamesan is the tool that will take you from the "Advanced Beginner" limbo to fluency. And it's totally free!
                            </p>
                        </a>
                        
                    </div>
                </div>
                <Footer />
        </>
    )
}

export default Homepage;