import React, { useEffect, useState } from 'react';
import { definedWord } from './VideoPlayer';
import './Dictionary.css';
import './SessionOverview.css';
import './Navigation.css'
import SettingsMenu from './SettingsMenuNew';
import { renderToString } from 'react-dom/server'
import { BooleanSetting, MultipleChoiceSetting, RestrictedChoiceSetting } from './SettingsMenuNew';
import { LoaderFunctionResponse } from './App'

function SidebarHomeView(props: { 
    settings: (BooleanSetting | RestrictedChoiceSetting | MultipleChoiceSetting)[], 
    word: definedWord | undefined, 
    context: [definedWord] | undefined, 
    dialogueStart: number, 
    dialogueEnd: number, 
    videoLink: string, 
    addedWordCount: number, 
    dictionaryLookupsCount: number, 
    updateAddedAnkiWords: React.Dispatch<React.SetStateAction<number>>,
    seriesDetails: LoaderFunctionResponse }) {

  const [menu, setMenu] = useState("home")
  const [ankiConnectResponse, setAnkiConnectResponse] = useState("failed") //possible values are: "", "failed", "denied", "connected"

  var currentIndex = props.seriesDetails.allSeriesEpisodes.indexOf(props.seriesDetails.currentEpisode)

  useEffect(() => {

    const timerId = setInterval(updateAnkiConnectState, 3000)
    return function cleanup() {
      clearInterval(timerId);
    };
  })

  async function invoke(action: String, version: number, params={}) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.addEventListener('error', (e) => reject('failed to issue request' + e));
        xhr.addEventListener('load', () => {
            try {
                const response = JSON.parse(xhr.responseText);
                if (Object.getOwnPropertyNames(response).length !== 2) {
                    throw new Error('response has an unexpected number of fields')
                }
                else if (!response.hasOwnProperty('error')) {
                    throw new Error('response is missing required error field')
                }
                else if (!response.hasOwnProperty('result')) {
                    throw new Error('response is missing required result field')
                }
                else if (response.error) {
                    throw response.error;
                }
                else {
                
                resolve(response.result);
                }
            } catch (e) {
                reject(e);
            }
        });

        xhr.open('POST', 'http://127.0.0.1:8765');
        xhr.send(JSON.stringify({action, version, params}));
    });
  }

  async function requestPermission() {
    await invoke("requestPermission", 6,)
  }

  async function addAsCard(){

    // to get a nice version of the sentence with furigana on each word. another field needs to be added to the server response
    // this field must have the furigana of the surface form of the word.
    // of course, there are other, more efficient solutions than this, but I'd like to keep things easy to understand
    // that way I can use the server for all kinds of other things too if I want.
    if (props.word && props.context) {

    let frontValuesSetting = props.settings.find(o => o.legend === "Anki card front values:")

    if (frontValuesSetting?.type === "MultipleChoiceSetting;") {
      var cardFrontText = (
        <>

        {
          frontValuesSetting.value.find(o => o.value === "word") &&
          <h1>
            <ruby>
              <span>{props.word.contextualForm}</span>
            </ruby>
          </h1>
        }

        {
          frontValuesSetting.value.find(o => o.value === "reading") &&
          <h1>
            <ruby>{ props.word.reading ? (
                            props.word.reading.map(function (x) {let val = Object.entries(x)[0]; return <><span>{val[0]}</span><rt>{(val[1] === val[0]) ? (<></>) : (<>{val[1]}</>)}</rt></>})
                            ) : (
                            <><span>{props.word.contextualForm}</span><rt>{props.word.contextualForm}</rt></>
                            )
                  }
            </ruby>
          </h1>
        }

        {frontValuesSetting.value.find(o => o.value === "sentence") &&
          props.context.map(function(x) {
            if (x === props.word) {
              return <span style={{color: "#b8bb26", fontSize: "35px"}}>{ x.contextualForm }</span>
            }
            else {
              return <span style={{fontSize: "35px"}}>{ x.contextualForm }</span>
            }
          })
        }

        {
          frontValuesSetting.value.find(o => o.value === "video") &&
          <>
            <br />
            <video src={`${props.videoLink}#t=${props.dialogueStart},${props.dialogueEnd}`} style={{height: "400px", aspectRatio: 16 / 9, borderRadius: "20px", marginTop: "20px"}} autoPlay></video>
          </>

        }

        {frontValuesSetting.value.find(o => o.value === "definition") &&
          <ol>
          { props.word.definitions.map((x, i) => <li key={i}>{ x.join('; ') }</li>) }
          </ol>
        }

        </>
      )
    } else {
      var cardFrontText = (
        <>
        {
          props.context.map(function(x) {
            if (x === props.word) {
              return <span style={{color: "#b8bb26", fontSize: "35px"}}>{ x.contextualForm }</span>
            }
            else {
              return <span style={{fontSize: "35px"}}>{ x.contextualForm }</span>
            }
            })
        }
        </>
      )
    }

    let backValuesSetting = props.settings.find(o => o.legend === "Anki card back values:")

    if (backValuesSetting?.type === "MultipleChoiceSetting;") {
      var cardBackText = (
        <>

        {
          backValuesSetting.value.find(o => o.value === "word") &&
          <h1>
            <ruby>
              <span>{props.word.contextualForm}</span>
            </ruby>
          </h1>
        }

        {
          backValuesSetting.value.find(o => o.value === "reading") &&
          <h1>
            <ruby>{ props.word.reading ? (
                            props.word.reading.map(function (x) {let val = Object.entries(x)[0]; return <><span>{val[0]}</span><rt>{(val[1] === val[0]) ? (<></>) : (<>{val[1]}</>)}</rt></>})
                            ) : (
                            <><span>{props.word.contextualForm}</span><rt>{props.word.contextualForm}</rt></>
                            )
                  }
            </ruby>
          </h1>
        }

        {backValuesSetting.value.find(o => o.value === "sentence") &&
          props.context.map(function(x) {
            if (x === props.word) {
              return <span style={{color: "#b8bb26", fontSize: "35px"}}>{ x.contextualForm }</span>
            }
            else {
              return <span style={{fontSize: "35px"}}>{ x.contextualForm }</span>
            }
          })
        }

        {backValuesSetting.value.find(o => o.value === "definition") &&
          <ol>
          { props.word.definitions.map((x, i) => <li key={i}>{ x.join('; ') }</li>) }
          </ol>
        }

        {
          backValuesSetting.value.find(o => o.value === "video") &&
          <>
          <br />
          <video src={`${props.videoLink}#t=${props.dialogueStart},${props.dialogueEnd}`} style={{height: "400px", aspectRatio: 16 / 9, borderRadius: "20px", marginTop: "10px"}} autoPlay></video>
          </>
        }

        </>
      )
    } else {
      var cardBackText = <>
        <h1>
          <ruby>{ props.word.reading ? (
                          props.word.reading.map(function (x) {let val = Object.entries(x)[0]; return <><span>{val[0]}</span><rt>{(val[1] === val[0]) ? (<></>) : (<>{val[1]}</>)}</rt></>})
                          ) : (
                          <><span>{props.word.contextualForm}</span><rt>{props.word.contextualForm}</rt></>
                          )
                }
          </ruby>
        </h1>

        <br />

        <ol>
          { props.word.definitions.map((x, i) => <li key={i}>{ x.join('; ') }</li>) }
        </ol>

        <br />

        <video src={`${props.videoLink}#t=${props.dialogueStart},${props.dialogueEnd}`} style={{height: "400px", aspectRatio: 16 / 9, borderRadius: "20px" }} autoPlay></video>

        <br />

      </>
      }

    

    let params = {
      "note": {
          "deckName": "Kamesan 🐢",
          "modelName": "Basic",
          "fields": {
              "Front": renderToString(<>{cardFrontText}</>),
              "Back": renderToString(<>{cardBackText}</>),
          },
          "options": {
              "allowDuplicate": true,
              "duplicateScope": "deck",
              "duplicateScopeOptions": {
                  "deckName": "Kamesan 🐢",
                  "checkChildren": false,
                  "checkAllModels": false
              }
          },
          "tags": [
              "kamesan"
          ],
          // "video": [{
          //     "url": `${props.videoLink}#t=${props.dialogueStart},${props.dialogueEnd}`,
          //     "filename": "test.mp4",
          //     "skipHash": "7e2c2f954ef6051373ba916f000168dc",
          //     "fields": [
          //         "Back"
          //     ]
          // }],
      }
    }

    await invoke("addNote", 6, params).then(() => props.updateAddedAnkiWords(old => {return old + 1}))
    }
  }

  function buttonPreventDefault(e: React.KeyboardEvent<HTMLButtonElement>) {
    e.preventDefault()
  }

  async function updateAnkiConnectState() {
    try {
      await invoke("version", 6)
      setAnkiConnectResponse("connected")
    }

    catch (e) {
      // console.log(e)
      // if (e === 'failed to issue request[object ProgressEvent]') {
        try {
          setAnkiConnectResponse("denied")
          await requestPermission()
          await invoke('createDeck', 6, {deck: 'Kamesan 🐢'});
        }
        catch(fuck) {
          setAnkiConnectResponse("failed")
        }
      }
      // else {
      //   setAnkiConnectResponse("denied")
      //   await requestPermission()
      //   await invoke('createDeck', 6, {deck: 'Kamesan 🐢'});
      // }
    // }
  }

    if (menu === "home") {
    if (props.word && props.context) {
    return (
        <>
        <div className="SidebarView">
            <div style={{"backgroundColor": "#1F4287", "borderBottom": "10px solid #1F4287"}}>Home</div>
            <div onClick={() => setMenu("settings")}>Settings</div>
            <div onClick={() => setMenu("episodes")}>Episodes</div>
        </div>
        <div className="Dictionary">
        <h1 className='WordKanji'>
            <ruby>
            { props.word.reading.map(function (x) {let val = Object.entries(x)[0]; return <><span>{val[0]}</span><rt>{val[1]}</rt></>}) }
            </ruby>
        </h1>

        {ankiConnectResponse === "connected" &&
        <button onClick={addAsCard} onKeyDown={buttonPreventDefault}>Add as Card</button>}

        {ankiConnectResponse === "failed" && 
        <a href="/newhere" className='AnkiNotConnected'>Connecting to Anki...</a>}

        {ankiConnectResponse === "denied" && 
        <span className='AnkiNotConnected'>Requesting to link with AnkiConnect...</span>}

        {/* <button onClick={requestPermission}>Request Permission</button> */}

        <ul>
            { props.word.definitions.map(x => <li>{ x.join('; ') }</li>)}
        </ul>
        <br /> <hr id="thinRule"/><br />

        <div className='SessionOverview'>
                <div className='headerInfo'>
                    <div className='totalNewWords'>
                        <h1>{props.addedWordCount}</h1>
                    </div>
                    <p>new Anki word{(props.addedWordCount === 1) ? "":"s"} this session!</p>
                </div>
                <div id='secondaryInfoLeft'>
                    <h2>{props.dictionaryLookupsCount}</h2>
                    <p>dictionary lookup{(props.dictionaryLookupsCount === 1) ? "":"s"}</p>
                </div>
                <div id='secondaryInfoRight'>
                    <h2>{(props.addedWordCount / props.dictionaryLookupsCount * 100).toPrecision(2)}%</h2>
                    <p>of clicked words added to Anki</p>
                </div>
            </div>
        </div>
        </>
    );}
    else {
        return(<>
        <div className="SidebarView">
            <div style={{"backgroundColor": "#1F4287", "borderBottom": "10px solid #1F4287"}}>Home</div>
            <div onClick={() => setMenu("settings")}>Settings</div>
            <div onClick={() => setMenu("episodes")}>Episodes</div>
        </div>
        <div className="Dictionary">
            {/* <img id="dude" src="https://webstockreview.net/images/clipart-turtle-finding-dory-1.png" /> */}
            <div id="noword">
            <h1>Welcome to Kamesan!</h1><br />
            <p>Click a word in the subtitles to get started</p>
            </div>
        </div>
        </>
        )
    }
    }
    else {
        if (menu === "settings"){
            return (<>
                <div className="SidebarView">
                    <div onClick={() => setMenu("home")}>Home</div>
                    <div style={{"backgroundColor": "#1F4287", "borderBottom": "10px solid #1F4287"}}>Settings</div>
                    <div onClick={() => setMenu("episodes")}>Episodes</div>
                </div>
                
                <SettingsMenu settings={props.settings} />
                </>
                )
        }
        else {
            if (menu === "episodes") {
                return (<>
                    <div className="SidebarView">
                        <div onClick={() => setMenu("home")}>Home</div>
                        <div onClick={() => setMenu("settings")} >Settings</div>
                        <div style={{"backgroundColor": "#1F4287", "borderBottom": "10px solid #1F4287"}}>Episodes</div>
                    </div>
                    <div className='Navigation'>
                    <div className='otherEpisodeContainer'>
                        {
                            props.seriesDetails.allSeriesEpisodes.slice((currentIndex - 1) < 0 ? 0 : currentIndex - 1, currentIndex + 4).map(x => 
                                    <a href={String(x.episode_number)}><div className='otherEpisodeBox'>
                                    <div id="thumbnail">
                                        <img src={x.poster_link} alt="the poster for this episode" />
                                    </div>
                                    <div id="details">
                                        <div id="title">{x.en_title}, Episode {x.episode_number}</div>
                                        <div id="viewCount">{x.view_count} Views</div>
                                        .
                                    </div>
                                    </div></a>
                                    )
                            }
                    </div>

                </div>
                    </>

                )
            }
            return <div></div>
        }
    }
  
}

export default SidebarHomeView;
