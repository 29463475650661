// import './SettingsMenu.css';
import './SettingsMenuNew.css';
import Select from 'react-select';

export interface BooleanSetting {
  type: "BooleanSetting";
  legend: String;
  value: boolean;
  setterFunction: (newValue: boolean) => void
}

export interface MultipleChoiceSetting {
  type: "MultipleChoiceSetting;"
  legend: String;
  value: { value: string; label: string; }[];
  options: {value: string, label: string}[],
  setterFunction: (newValue: { value: string; label: string; }[]) => void
}

export interface RestrictedChoiceSetting {
  type: "RestrictedChoiceSetting"
  legend: String;
  value: {value: string, label: string};
  options: {value: string, label: string}[]
  setterFunction: (newValue: {value: string, label: string}) => void
}

function SettingsMenu(props: {settings: (BooleanSetting | MultipleChoiceSetting | RestrictedChoiceSetting)[]}) {
  return (
    <div className='SettingsMenu'>
      {props.settings.map(x => {
        if (x.type === "BooleanSetting"){
          return <div className='settingsEntry'>
          <div className="settingsLegend">{x.legend}</div>
          <div className="toggleButton" onClick={() => {x.setterFunction(!x.value)}}>{ x.value ? "✅" : "❌" }</div>
          </div>
          }
          else if (x.type === "MultipleChoiceSetting;"){

            const options = x.options

            return (
              <div className='settingsEntry'>
              <div className='settingsLegend'>{x.legend}</div>
              <div className='selectContainer'>
              <Select onChange={(selected) => {x.setterFunction(selected as {value: string; label: string}[])}} value={x.value} options={options} isMulti/>
              </div>
              </div>
            )
            }
          else if (x.type === "RestrictedChoiceSetting"){

            const options = x.options

            return (
              <div className='settingsEntry'>
              <div className='settingsLegend'>{x.legend}</div>
              <div className='selectContainer'>
              <Select onChange={(selected) => {x.setterFunction(selected as {value: string; label: string})}} value={x.value} options={options}/>
              </div>
              </div>
            )
            }
      })}
      </div>
  )

}



export default SettingsMenu;
