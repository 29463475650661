import { useRouteError } from "react-router-dom";
import "./ErrorPage.css"
import Footer from "./Footer";

interface RouteError {
  status: number,
  statusText: string,
  internal: boolean,
  data: string,
  error: Error
}

export default function ErrorPage() {
    const error = useRouteError() as RouteError;
    console.error(error);
    console.log(error)

    if (error.status) {
      return (
        <>
        <div id="error-page">
          <h1>{error.status}</h1>
          <h2>{error.statusText}</h2>
          <div className="buttonContainer">
          <a href="/"><button>Back to Safety</button></a>
          </div>
        </div>
        <Footer />
        </>
      )
    }
    return (
      <>
      <div id="error-page">
        <h1>Oh No!</h1>
        <h2>Something's gone very wrong...</h2>
        <div className="buttonContainer">
            <a href="/"><button>Back to Safety</button></a>
          </div>
      </div>
      <Footer />
      </>
    );
}