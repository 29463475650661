import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App, {loader as videoLoader} from './App';
import Homepage from './Homepage'
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import BrowseSeries, {loader as browseSeriesLoader} from './BrowseSeries';
import ViewSeries, {loader as viewSeriesLoader} from './ViewSeries';
import Philosophy from './Philosophy'
import NewHere from './NewHere';
import Faq from './FAQ';
import { BrowserView, MobileView } from 'react-device-detect';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
    errorElement: <ErrorPage />
  },
  {
    path: "/browse",
    element: <BrowseSeries />,
    errorElement: <ErrorPage />,
    loader: browseSeriesLoader
  },
  {
    path: "/series/:seriesId",
    element: <ViewSeries />,
    errorElement: <ErrorPage />,
    loader: viewSeriesLoader
  },
  {
    path: "/watch/:seriesId/:episodeNumber",
    element: <App />,
    errorElement: <ErrorPage />,
    loader: videoLoader
  },
  {
    path: "/philosophy",
    element: <Philosophy />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/newhere",
    element: <NewHere />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/faq",
    element: <Faq />,
    errorElement: <ErrorPage />,
  }
]);

root.render(
  <React.StrictMode>
    <BrowserView>
    <div className='navbar'>
      <a href='/'>
      <img src='https://4.bp.blogspot.com/-m0He3E-2jx0/WK7ephD9kQI/AAAAAAABB9w/bvJxPUwPF7Q7N_1gphv3MFucmWFL20_XACLcB/s800/character_turtle_ko.png'></img>
      </a>
      <a href='/browse'><div id='navButton'><span>Home</span></div></a>
      <a href='/faq'><div id='navButton'><span>FAQ</span></div></a>
      <a href='https://www.patreon.com/Kamesan'><div id='navButtonDonate'><span>Donate</span></div></a>
    </div>
    <RouterProvider router={router} />
    </BrowserView>
    <MobileView>
    <div id="error-page-no-mobile">
          <h1>Sorry!</h1>
          <h2>This site doesn't support mobile phones (yet). <br /><br />Please either try again on a different device, or check back soon</h2>
          {/* <div className="buttonContainer"> */}
          {/* <a href="/"><button>Back to Safety</button></a> */}
          {/* </div> */}
        </div>
    </MobileView>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
